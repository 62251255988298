<template>
    <div class="BigBox">
        <!-- 头部 -->
        <div class="TouBu">
            <!-- 按钮 -->
            <img @click="GoHome" src="../../assets/ConstructionLandscape/Home.png" alt=""
                style="height:70%;margin-right: 50vh;cursor: pointer;">
            <!-- 标题 -->
            <div class="NavTitle">
                <div>红色文化馆</div>
            </div>
            <!-- 按钮 -->
            <img @click="GoLogin" src="../../assets/ConstructionLandscape/GeRen.png" alt=""
                style="height: 70%;margin-left: 50vh;cursor: pointer;">
        </div>
        <div class="ModoelNavBox">
            <div class="ModoelBox" v-for="(ModoelNavBoxList, index) in ModoelNavBoxList" :key="index"
                :class="index === ModoelIndex ? 'active' : ''" @click="TabModoel(index)">
                <div style="width: 75%;display: flex;justify-content: center;">{{ ModoelNavBoxList.name }}</div>
            </div>
        </div>
        <!-- 历史脉络 -->
        <div v-if="ShowModoel == 0" class="RedShow">
            <!-- 卡片化层叠轮播图 -->
            <div class="CulteraCardOne">
                <div class="swiper-container swiper2">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide " v-for="(HistoryHistoryList, index) in HistoryHistoryList" :key="index">
                            <div class="RedBj">
                                <!-- 图片 -->
                                <div class="RedPicture"> <img :src=HistoryHistoryList.logoUrl alt="网络信号不好"
                                        style="width:100%;height:100%;"> </div>
                                <!-- 标题 -->
                                <div class="RedTitle">{{HistoryHistoryList.title}}</div>
                                <!-- 点击按钮 -->
                                <div class="RedButton" @click="GoHistoryHistoryDetails(HistoryHistoryList)">MORE</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 红色征集 -->
            <div class="RedButtonOne" @click="figure()">
                <div class="RedPen"> <img src="../../assets/RedCultureCenter/redPen.png" alt="" style=" height:100%;">
                </div>
                <div class="RedScript">红色征集</div>
            </div>
        </div>
        <!-- 红色人物 -->
        <div v-if="ShowModoel == 1" class="RedShow">
            <!-- 卡片化层叠轮播图 -->
            <div class="CulteraCardOne">
                <div class="swiper-container swiper2">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide " style="cursor: pointer;" v-for="(historicalPersonageList, index) in historicalPersonageList" :key="index" @click="GoRedFigureDetails(historicalPersonageList)">
                            <div class="RedBj" style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;" :style="{backgroundImage:'url(' + historicalPersonageList.imgs + ')'}">
                                <div style="width: 36%;height: 100%;display: flex;flex-direction: column-reverse;">
                                    <div style="width: 100%;height: 70%;color: rgb(255,237,201); display: flex;justify-content: center;overflow: hidden;">
                                        “<br>红<br>色<br>湖<br>南<br>”<br>人<br>物<br>专<br>题<br>
                                    </div>
                                </div>
                                <div style="width: 60%;height: 100%;display: flex;align-items: center;">
                                    <div style="width: 88%;height: 88%;position: relative;top: -1%;color: black;">
                                        <div style="font-size: 3.5vh;font-family: 'YeGenYou';">{{ historicalPersonageList.title }}</div>
                                        <div class="RedFigurePropaganda">{{ historicalPersonageList.propaganda }}</div>
                                        <img style="height: 48%;width: 100%;" :src="historicalPersonageList.logoUrl" alt="">
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 红色征集 -->
            <div class="RedButtonOne" @click="figure()">
                <div class="RedPen"> <img src="../../assets/RedCultureCenter/redPen.png" alt="" style=" height:100%;">
                </div>
                <div class="RedScript">红色征集</div>
            </div>
        </div>
        <!-- 红色旅游 -->
        <div v-if="ShowModoel == 2">

        </div>
        <!-- 红色影像 -->
        <div v-if="ShowModoel == 3" class="RedShow">
            <!-- 卡片化层叠轮播图 -->
            <div class="CulteraCardOne">
                <div class="swiper-container swiper2">
                    <div class="swiper-wrapper">
                        <div class="swiper-slide " v-for="(HistoryHistoryList, index) in HistoryHistoryList" :key="index">
                            <div class="RedBj">
                                <!-- 图片 -->
                                <div class="RedPicture"> <img :src=HistoryHistoryList.logoUrl alt=""
                                        style="width:100%;height:100%;"> </div>
                                <!-- 标题 -->
                                <div class="RedTitle">{{ HistoryHistoryList.title }}</div>
                                <!-- 点击按钮 -->
                                <div class="RedButton" @click="checkVideoFun()">MORE</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- 红色征集 -->
            <div class="RedButtonOne" @click="figure()">
                <div class="RedPen"> <img src="../../assets/RedCultureCenter/redPen.png" alt="" style=" height:100%;">
                </div>
                <div class="RedScript">红色征集</div>
            </div>
        </div>
        <!-- 红色革命遗址 -->
        <div v-if="ShowModoel == 4"></div>
        <!-- 视频播放 -->
        <!-- 外层的遮罩 v-if用来控制显示隐藏 点击事件用来关闭弹窗 -->
        <!--  -->
        <div class="mask" @click="masksCloseFun" v-if="videoState == true"></div>
        <!-- 弹窗 -->
        <div>
            <div class="videomasks" v-if="videoState == true">
                <!-- 视频：h5的视频播放video -->
                <!-- controls="controls" -->
                <video src="" controls="controls" autoplay style="width:100%;height: 100%;" class="Video">
                </video>
            </div>
        </div>
    </div>
</template>
  
<script>
import $ from "jquery";
// import Swiper from "swiper";
// import "swiper/dist/css/swiper.min.css";
// import "swiper/dist/js/swiper.min";
import { log } from 'console';
export default {
    data() {
        return {
            // 视频的隐藏
            videoState: false,
            ModoelNavBoxList: [
                {
                    name: '历史脉络'
                },
                {
                    name: '红色人物'
                },
                {
                    name: '红色旅游'
                },
                {
                    name: '红色影像'
                },
                {
                    name: '红色革命遗址'
                },
            ],
            ModoelIndex: 0,
            ShowModoel: 0,
            // 历史脉络首页数据
            HistoryHistoryList:'',
            // 红色人物列表数据
            historicalPersonageList:'',
            // 红色人物背景列表
            RedFigureBGList:[
                {url:require('../../assets/RedCultureCenter/RedFigure/RedRenHei.png')},
                {url:require('../../assets/RedCultureCenter/RedFigure/RedRenHong.png')},
                {url:require('../../assets/RedCultureCenter/RedFigure/RedRenHuang.png')},
                {url:require('../../assets/RedCultureCenter/RedFigure/RedRenHui.png')},
                {url:require('../../assets/RedCultureCenter/RedFigure/RedRenLan.png')},
                {url:require('../../assets/RedCultureCenter/RedFigure/RedRenLv.png')},
                {url:require('../../assets/RedCultureCenter/RedFigure/RedRenZhong.png')},
            ]

        }
    },
    created() {
        // 获取历史脉络列表数据
        this.GethistoricalContextList()
        
    },
    updated() {
        this.initSwiperOne()
    },
    mounted() {
        this.initSwiperOne()
        // 影像列表
        this.VideoList()
    },
    methods: {
        // 获取红色人物列表数据
        GetHistoricalPersonageList(){
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/personage/historicalPersonageList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },
                data:{}
            };
            axios(config).then((res) => {
                this.historicalPersonageList = res.data.data
                let length = this.RedFigureBGList.length
                let e = 0
                for (var i = 0; i < this.historicalPersonageList.length;i++) {
                    this.historicalPersonageList[i].imgs = this.RedFigureBGList[e].url
                    if(e < length){
                        e++
                    }else{
                        e = 0
                    }
                }
            }).catch(function (error) {});
        },
        // 跳转红色人物详情
        GoRedFigureDetails(res){
            this.$router.push({
                path: '/RedFigureDetails',
                query: {
                    GoRedFigureDetails: res.personageId
                }
            })
        },
        // 跳转红色脉络详情页
        GoHistoryHistoryDetails(HistoryHistoryList){
            this.$router.push({
                path: '/more',
                query: {
                    contextId: HistoryHistoryList.contextId
                }
            })
        },
        // 点击播放视频
        checkVideoFun() {

            // if(){}
            this.videoState = true;

        },
        masksCloseFun() {
            this.videoState = false;
        },
        // 返回个人中心
        GoLogin() {
            this.$router.push('/login');
        },
        // 返回首页
        GoHome() {
            this.$router.push('/');
        },
        // 点击跳转红色人物提交页面
        figure() {
            this.$router.push('/CharactersSubmit');
        },
        // 切换模块
        TabModoel(index) {
            this.ModoelIndex = index
            this.ShowModoel = index
            if(index == 1){
                // 获取红色人物列表数据
                this.GetHistoricalPersonageList()
            }
            if(index == 3){
                // 获取红色影像首页列表数据
                this.GetRedCultureVideoList()
            }
        },
        
        initSwiperOne() {
            setTimeout(() => {
                var swiper = new Swiper(".swiper2", {
                    effect: "coverflow",

                    preloadImages: true,
                    centeredSlides: true,
                    updateOnImagesReady: true,
                    observer: true,//修改swiper自己或子元素时，自动初始化swiper 
                    observeParents: true,//修改swiper的父元素时，自动初始化swiper
                    slideToClickedSlide: true,
                    slidesPerView: "auto",
                    initialSlide: 2,
                    paginationClickable: true,
                    loopedSlides: 2,
                    pagination: {
                        el: '.swiper-pagination.swiper2',
                        // dynamicBullets: true,
                    },

                    onSlideChangeEnd: (swiperHere) => {

                    },



                    coverflow: {
                        rotate: 10,
                        stretch: -70,
                        depth: 120,
                        modifier: 3,
                        slideShadows: false,
                    },

                });
            }, 300)
        },
        VideoList() {
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/scenic/scenicIndex',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },

            };
            axios(config)
                .then((res) => {
                    if (res.data.code == 200) {
                        this.ScenicArea = res.data.data
                    }
                })
                .catch(function (error) {
                });
        },
        // 获取红色影像列表数据
        GetRedCultureVideoList(){
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/redCultureVideo/redCultureVideoList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },

            };
            axios(config).then((res) => {
                this.HistoryHistoryList = res.data.data
            }).catch(function (error) {});
        },
        // 获取历史脉络列表数据
        GethistoricalContextList(){
            var axios = require('axios');
            var config = {
                method: 'get',
                url: this.$Schttp + '/vtp/app/historicalContext/historicalContextList',
                headers: {
                    Authorization: "Bearer " + this.$cookies.get("token"),
                    "Content-Type": "application/json",
                },

            };
            axios(config).then((res) => {
                this.HistoryHistoryList = res.data.data
            }).catch(function (error) {});
        }
    }

}
</script>
  
<style lang="less" scoped>
* {
    padding: 0;
    margin: 0;
}
@font-face {
  font-family: "YeGenYou"; // 自定义的名字
  src: url("../../ZiTi/YeGenYouMaoBi.ttf"); //下载的包
}
.BigBox {
    width: 100%;
    height: 100%;
    min-width: 1440px;
    background-image: url('../../assets/RedCultureCenter/RedCultureCenterQuanBG.png');
    background-size: 100% 100%;
    color: rgb(22, 245, 255, );
    .RedFigurePropaganda{
        width: 100%;
        height: 41%;
        line-height: 25px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        font-size: 2vh;
    }
    .TouBu {
        width: 100%;
        height: 14%;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .NavTitle {
            margin-top: -7vh;
            font-size: 4vh;
            font-weight: 1000;
            margin-right: 4vh;
            text-align: center; //水平居中
            text-shadow: 0 0 2px rgb(0, 187, 255), 0 0 10px rgb(0, 204, 255), 0 0 10px rgb(0, 213, 255), 0 0 10px rgb(0, 213, 255); //设置发光效果
        }
    }

    .ModoelNavBox {
        width: 85%;
        height: 10%;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;

        .ModoelBox {
            width: 16%;
            height: 100%;
            background-image: url('../../assets/RedCultureCenter/BlueNavBG.png');
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            font-size: 3vh;
            cursor: pointer;
        }

        .active {
            background-image: url('../../assets/RedCultureCenter/RedNavBG.png') !important;
            color: red;
        }
    }

    .RedShow {
        width: 87%;
        height: 75%;
        margin: 0 auto;

        .CulteraCardOne {
            width: 100%;
            height: 70%;

            .swiper-container {
                z-index: 0;
                width: 100%;
                height: 100%;

                .swiper-wrapper {
                    z-index: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;
                    align-items: center;

                    .swiper-slide {
                        width: 18%;
                        height: 80%;

                        .RedBj {
                            width: 100%;
                            height: 100%;
                            display: flex;
                            align-items: center;
                            flex-direction: column;
                            background: url(../../assets/RedCultureCenter/bg.png);
                            background-size: 100% 100%;

                            .RedPicture {
                                width: 80%;
                                height: 38%;
                                margin-top: 3vh;
                            }

                            .RedTitle {
                                width: 80%;
                                color: rgba(255, 255, 255, 1);
                                font-size: 2.2vh;
                                letter-spacing: 0.2vh;
                                line-height: 1.8;
                                display: -webkit-box;
                                /*将对象转为弹性盒模型展示*/
                                -webkit-box-orient: vertical;
                                /*设置弹性盒模型子元素的排列方式*/
                                -webkit-line-clamp: 2;
                                /*限制文本行数*/
                                overflow: hidden;
                                margin-top: 2vh;
                            }

                            .RedButton {
                                width: 7.5vh;
                                height: 7.3vh;
                                border-radius: 100%;
                                margin-top: 3.4vh;
                                background: url(../../assets/RedCultureCenter/bonfire.png);
                                background-size: 100% 100%;
                                color: rgba(255, 255, 255, 1);
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 1.8vh;
                                cursor: pointer;
                            }
                        }

                        .RedBjOne {
                            width: 100%;
                            height: 100%;
                            // background: url(../../assets/RedCultureCenter/bg.png);
                            background-size: 100% 100%;
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            .RedPictureOne {
                                width: 95%;
                                height: 95%;
                            }

                        }

                    }

                }

            }
        }

        .RedButtonOne {
            width: 16%;
            height: 8%;
            margin: 0 auto;
            margin-top: 8vh;
            background: url(../../assets/RedCultureCenter/RedButton.png);
            background-size: 100% 100%;
            display: flex;
            align-items: center;
            cursor: pointer;

            .RedPen {
                width: 15%;
                height: 70%;
                margin-left: 4vh;
            }

            .RedScript {
                color: rgba(255, 255, 255, 1);
                font-size: 2.5vh;
                margin-left: 1.5vh;

            }
        }
    }

    .mask {
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        z-index: 888;
        background-color: #000000;
        opacity: 0.6;
    }

    /* 内容层 z-index要比遮罩大，否则会被遮盖 */
    .videomasks {
        width: 60%;
        height: 60%;
        max-width: 1200px;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        z-index: 9999;
    }
}
</style>